$black: #232429;
$gray900: #393a3f;
$gray800: #55565b;
$gray700: #75767b;
$gray600: #828388;
$gray500: #919297;
$gray400: #adaeb3;
$gray300: #c1c2c7;
$gray200: #d3d4d9;
$gray100: #dedfe4;
$gray50: #f3f4f9;
$white: #ffffff;

$primary: #478bff;
$primary900: #2060ce;

$section-bg: #101021;
$background: #161617;
