@charset "utf-8";
@import "_font.scss", "_color.scss";

/* resume */
.resume {
    #resume {
        padding-top: 40px;
        padding-bottom: 100px;
    }
    article {
        padding: 40px 0;
        color: $gray200;
        line-height: 2;
        h4 {
            margin: 0 0 20px;
            @include content-title;
            font-weight: 600;
            color: $gray50;

            small {
                font-weight: 400;
                color: $primary;
                @include sub-title;
            }
        }
        p + h4 {
            margin-top: 80px;
        }
    }
    .li-contact li,
    article p {
        display: flex;
    }
    article p .date,
    .li-contact span {
        display: inline-block;
        min-width: 150px;
        font-weight: 500;
        color: $gray50;
    }
    a:hover {
        text-decoration: underline;
    }
    .li-dash {
        li {
            position: relative;
            padding-left: 10px;
            color: $gray400;
            &:before {
                content: "-";
                position: absolute;
                left: 0;
                top: 0px;
            }
            strong {
                color: $gray50;
                font-weight: 400;
            }
        }
    }
    dt {
        color: #f5f5f7;
        font-weight: 600;
    }
    .project-table {
        width: 100%;
        border-bottom: 1px solid #86868b;
        white-space: nowrap;
        thead {
            text-align: left;
            background: #1750ac;
            th {
                color: #f5f5f7;
            }
        }
        tr:not(:first-child) {
            border-top: 1px solid rgb(255 255 255 / 10%);
        }

        tbody tr:hover {
            background: rgb(255 255 255 / 10%);
        }
        th,
        td {
            padding: 2px 10px;
        }
    }
    h5 {
        display: block;
        margin: 32px 0 16px;
        @include body-text;
        color: $gray50;
        font-weight: 600;
        a {
            display: inline-block;
            margin-left: 8px;
        }
        small {
            display: inline-block;
            margin-top: 4px;
            @include small-text;
            font-weight: 400;
            color: $gray200;
        }
        & + .des {
            @include small-text;
            color: $gray400;
        }
    }

    #back_btn {
        position: fixed;
        right: 20px;
        bottom: 30px;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        color: #f5f5f7;
        border-radius: 50%;
        background: $primary900;
        box-shadow: 0 0 20px 20px rgb(0 0 0 / 10%);
        opacity: 0;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-delay: 1s;
        cursor: pointer;
        border: 0;
    }
    &.on #back_btn {
        animation-name: backBtn;
        &:hover {
            text-decoration: none;
        }
        span {
            font-size: 36px;
        }
    }
    /* scollbar */
    #nav-scroll {
        z-index: 1000;
        position: fixed;
        right: 20px;
        top: 50%;
        transform: translateY(-50%) scale(0.85);
        display: flex;
        flex-flow: column;
        align-items: center;
        opacity: 0;
    }
    &.on #nav-scroll {
        animation-name: navi;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
    }
    @keyframes navi {
        0% {
            opacity: 0;
            transform: translateY(-50%) scale(0.85);
        }
        70% {
            opacity: 1;
            transform: translateY(-50%) scale(1.03);
        }
        100% {
            opacity: 1;
            transform: translateY(-50%) scale(1);
        }
    }
    #scroll-bar {
        position: relative;
        width: 14px;
        height: 150px;
        border-radius: 50px;
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        background: rgba(129, 129, 133, 0.72);
        box-shadow: 0 0 5px 5px rgb(0 0 0 / 5%);
        transition: all 0.1s ease-in-out;
        .inner {
            position: absolute;
            left: 50%;
            top: 2%;
            width: 1px;
            height: calc(96% - 8px);
            margin-left: -1px;
        }

        .current-indicator {
            display: block;
            position: absolute;
            left: 50%;
            top: 20%;
            transform: translateX(-50%);
            width: 8px;
            height: 8px;
            background: #f5f5f7;
            opacity: 0.8;
            border-radius: 50%;
            transition: all 0.1s ease-in-out;
        }
    }

    #scroll-top {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #fff;
        font-weight: bold;
        font-size: 8px;
        background: #2997ff;
        box-shadow: 0 0 5px 5px rgb(0 0 0 / 5%);

        &::before {
            content: "";
            display: block;
            border-bottom: 5px solid #fff;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
        }
    }

    .at4 {
        .flex-row {
            flex-wrap: wrap;
            gap: 20px 0;
        }
        .skill-dl {
            dd {
                display: flex;
                align-items: center;
                gap: 8px;
                &::after {
                    content: "";
                    display: block;
                    height: 1px;
                    border-top: 5px dotted #478bff;
                }
                &[data-level="1"]::after {
                    width: 6px;
                    opacity: 0.5;
                    filter: saturate(0);
                }
                &[data-level="2"]::after {
                    width: 16px;
                    opacity: 0.75;
                    filter: saturate(0.5);
                }
                &[data-level="3"]::after {
                    width: 24px;
                    opacity: 1;
                }
            }
        }

        .level-li {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid $gray900;

            li {
                display: flex;
                align-items: center;

                gap: 12px;
                @include small-text;
                color: $gray100;

                .circle {
                    display: block;
                    width: 24px;

                    &::after {
                        content: "";
                        display: block;
                        height: 1px;
                        border-top: 5px dotted #478bff;
                    }
                    &[data-level="1"]::after {
                        width: 6px;
                        opacity: 0.5;
                        filter: saturate(0);
                    }
                    &[data-level="2"]::after {
                        width: 16px;
                        opacity: 0.75;
                        filter: saturate(0.5);
                    }
                    &[data-level="3"]::after {
                        width: 24px;
                        opacity: 1;
                    }
                }
            }
        }
    }

    @keyframes backBtn {
        0% {
            opacity: 0;
            transform: scale(0);
        }
        70% {
            opacity: 1;
            transform: scale(1.03);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
}
@media (max-width: 999px) {
    .resume {
        article {
            padding: 28px 0;
            p {
                margin-bottom: 20px;
                display: block;
                line-height: 1.6;
                .date {
                    display: block;
                }
            }
        }
        .project-table {
            width: auto;
        }
        .table-responsive {
            max-width: 100%;
            overflow-x: scroll;
        }
        .li-contact {
            li {
                display: block;
                margin-bottom: 20px;
                line-height: 1.6;
                span {
                    display: block;
                }
            }
        }
        .at4 {
            .col {
                flex-basis: 100%;
            }
        }
        #back_btn {
            width: 60px;
            height: 60px;
        }
        &.on {
            #nav-scroll {
                display: none;
            }
            #back_btn {
                span {
                    font-size: 28px;
                }
            }
        }
    }
}
