//계층구조
@mixin hero {
    font-size: 64px;
    @media (max-width: 999px) {
        font-size: 40px;
    }
}
@mixin main-title {
    font-size: 48px;
    @media (max-width: 999px) {
        font-size: 32px;
    }
}
@mixin second-title {
    font-size: 32px;
    @media (max-width: 999px) {
        font-size: 24px;
    }
}
@mixin content-title {
    font-size: 24px;
    @media (max-width: 999px) {
        font-size: 20px;
    }
}
@mixin sub-title {
    font-size: 18px;
}
@mixin body-text {
    font-size: 16px;
}
@mixin small-text {
    font-size: 14px;
}
@mixin caption {
    font-size: 12px;
}

//스타일
@mixin text-gradient {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    background-color: #478bff;
    background-image: linear-gradient(145deg, #00ffff, #478bff);
}

@mixin text-outline {
    font-weight: bold;
    text-shadow:
        1px 1px 0 rgba(255, 255, 255, 0.15),
        -1px -1px 0 rgba(255, 255, 255, 0.15),
        1px -1px 0 rgba(255, 255, 255, 0.15),
        -1px 1px 0 rgba(255, 255, 255, 0.15);
}
