@charset "utf-8";
@import "_font.scss", "_color.scss";

.renew {
    .renew {
        overflow: hidden;
        &.on {
            overflow: initial;
        }
    }

    /* loading */
    #loading {
        z-index: 2000;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        font-size: 90px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        background: #000;
        white-space: nowrap;
        perspective: 2000px;
    }
    #loading > div {
        visibility: hidden;
        z-index: 1900;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
    }
    #loading > div.active {
        z-index: 2000;
        visibility: visible;
    }
    #loading > div.dark {
        color: #f5f5f7;
        background: #000;
    }
    #loading > div.light {
        color: #161617;
        background: #fff;
    }

    /* loading-phase1 */
    #loading #phase1 p {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.5s ease;
    }
    #loading #phase1 span {
        opacity: 0;
    }
    #loading #phase1 p.active span {
        opacity: 1;
    }
    #loading #phase1 .l1 .t1 {
        transition-delay: 0s;
    }
    #loading #phase1 .l1 .t2 {
        transition-delay: 0.7s;
    }
    #loading #phase1 .l1 .t3 {
        transition-delay: 0.9s;
    }
    #loading #phase1 .l1 .t4 {
        transition-delay: 1.1s;
    }
    #loading .main-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /* loading-phase2~4 */
    #loading .trait .main-text {
        height: 1em;
    }
    #loading .main-text .sup-text {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: -50px;
        transform: translateX(-50%);
        display: block;
        font-size: 24px;
        letter-spacing: -1em;
        margin-left: -1em;
    }
    #loading .main-text.active .sup-text {
        opacity: 1;
        letter-spacing: 1.5em;
        margin-left: 1.5em;
        transition: letter-spacing 0.7s ease-out;
    }
    #loading .trait .main-text span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) scalex(1);
        letter-spacing: -0.03em;
        line-height: 1;
        mix-blend-mode: multiply;
        transition: all 0.7s ease;
    }
    #loading .trait .main-text.active span {
        transform: translateX(-50%) scalex(1.3);
    }
    #loading .main-text .text-c {
        color: #00aeef;
    }
    #loading .main-text .text-m {
        color: #d33d76;
    }
    #loading .main-text .text-y {
        color: #fee716;
    }
    #loading .main-text.active .text-c {
        margin-left: 1.15em;
    }
    #loading .main-text.active .text-y {
        margin-left: -1.15em;
    }

    /* loading-phase5 */
    #loading #phase5 p {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    #loading #phase5 .l1 {
        height: 1em;
        overflow: hidden;
        transition: all 0.5s ease;
    }
    #loading #phase5 .l1 span {
        display: inline-block;
        line-height: 1;
        vertical-align: top;
        transition: all 1s ease;
    }

    #loading #phase5 .l1 .t1 {
        transition-delay: 0s;
    }
    #loading #phase5 .l1 .t2 {
        transition-delay: 0.5s;
    }
    #loading #phase5 .l1 .t3 {
        transition-delay: 0.9s;
    }

    #loading #phase5 .l2 {
        width: 1200px;
        max-width: 100%;
        text-align: left;
        padding: 0 15px;
    }
    #loading #phase5 .l2 span {
        opacity: 0;
        display: block;
        line-height: 1;
        font-size: 120px;
        font-weight: bold;
        letter-spacing: -0.04em;
        transition: all 0.5s ease;
    }
    #loading #phase5 .l2 .t1 {
        transition-delay: 0s;
    }
    #loading #phase5 .l2 .t2 {
        transition-delay: 0.5s;
    }
    #loading #phase5 .l2 .t3 {
        transition-delay: 1s;
    }
    #loading #phase5 .l2.active span {
        opacity: 1;
    }

    #loading-close {
        opacity: 0;
        display: flex;
        z-index: 2020;
        position: absolute;
        right: 50px;
        bottom: 50px;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        font-size: 16px;
        font-weight: bold;
        color: #161617;
        background: #fee716;
        overflow: hidden;
        transition: all 0.3s ease-in;
    }
    #loading-close:hover {
        animation-name: fliping-btn;
        animation-duration: 1s;
        animation-fill-mode: both;
    }
    #loading-close.active {
        opacity: 1;
    }
    @keyframes fliping-btn {
        0% {
            transform: rotateY(0);
        }
        25% {
            transform: rotateY(-20deg);
        }
        50% {
            transform: rotateY(20deg);
        }
        75% {
            transform: rotateY(-20deg);
        }
        100% {
            transform: rotateY(0);
        }
    }

    /* main-content */
    .main-content {
        section {
            z-index: 10;
            position: relative;
            display: flex;
            flex-flow: column;
            justify-content: center;
            // min-height: 100vh;
            padding: 120px 0;
            background: #161617;
            // border-bottom: 1px solid rgb(255 255 255 / 10%);
        }

        [data-acive="fadeup"] {
            transform: translateY(50px);
            opacity: 0.5;
            transition: all 0.3s ease-out;
        }
        section.active [data-acive="fadeup"] {
            transform: translateY(0);
            opacity: 1;
        }
        [data-acive="fadeleft"] {
            transform: translateX(-50px);
            opacity: 0;
            transition: all 0.3s ease-out;
        }
        section.active [data-acive="fadeleft"] {
            transform: translateY(0);
            opacity: 1;
        }
        [data-active-idx="1"] {
            transition-delay: 0.3s;
        }
        [data-active-idx="2"] {
            transition-delay: 0.4s;
        }
        [data-active-idx="3"] {
            transition-delay: 0.5s;
        }
        [data-active-idx="4"] {
            transition-delay: 0.6s;
        }
    }
}

/* 새로 작성하는 코드 */
.renew {
    //common
    section {
        h2 {
            margin: 0 0 40px;
            @include main-title;

            font-weight: bold;
            color: $gray100;

            & + p {
                margin: -24px 0 40px;
                @include sub-title;
                color: $gray300;
                strong {
                    font-weight: bold;
                    color: $gray200;
                }
            }
        }
    }

    //key visual
    #key-visual {
        padding: 0 0 120px;
        height: 200vh;
        justify-content: flex-start;
        background: $section-bg;
        background: linear-gradient(90deg, $section-bg, #0b0b17, $section-bg);
        .container {
            height: 100%;
            padding: 0;
            margin: 0;
        }
        h2 {
            color: $white;
            text-align: center;
        }
        .visual-wrap {
            position: fixed;
            top: 120px;
            display: flex;
            width: 100%;
            height: calc(100vh - 200px);
            align-items: center;
            flex-flow: column;
            max-width: 100vw;

            .txt-box {
                text-align: center;
                h2 {
                    margin-top: 32px;
                    @include hero;
                    line-height: 1.4;
                    font-weight: 700;
                    .highlight {
                        @include text-gradient;
                    }
                    small {
                        display: block;
                        font-weight: 400;
                        @include second-title;
                        color: #fff;
                    }
                }
            }
        }
        .cowork-text {
            font-size: 5vw;
            white-space: nowrap;
            text-transform: uppercase;
            position: absolute;
            left: 30px;
            bottom: -100vh;
            writing-mode: vertical-rl;
            @include text-outline;
            color: $section-bg;
        }
        .job-text {
            font-size: 10vw;
            white-space: nowrap;
            text-transform: uppercase;
            position: absolute;
            right: 30px;
            top: 0vh;
            writing-mode: vertical-rl;
            @include text-outline;
            color: #101021;
        }

        .canvas-wrap {
            width: 100%;
            max-width: 1920px;
            aspect-ratio: 1920 / 850;
            overflow: hidden;
        }
        #canvas {
            display: block;
            cursor: grab;
            &:active {
                cursor: grabbing;
            }
        }
    }

    //example
    #example {
        .webapp-wrap {
            height: 200vh;
        }
        .mobile-wrap {
            position: sticky;
            top: 0;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #efefef;

            .app-screen {
                z-index: 10;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 0;
                width: 100vw;
                height: 100vh;
                overflow: hidden;
                // border: 1px solid $primary;
                .screen-img {
                    opacity: 1;
                    width: 100%;
                    height: 100%;
                    background: $primary;
                    background: #135ce5 url("../img/renew/mobile_screen.webp") no-repeat center / contain;
                }
            }

            .img-container {
                z-index: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 600px;
                max-width: 100%;
                margin: 0 auto;
                .mockup-img {
                    width: 100%;
                }
                .sample-screen {
                    z-index: 0;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 8.3%;
                    width: 38.6%;
                    aspect-ratio: 9 / 19.4;
                    border-radius: 30px;
                    background: url(../img/renew/mobile_screen.webp) no-repeat center / contain;
                }
                .camera {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 10%;
                    width: 13%;
                    height: 3%;
                    background: #000;
                    border-radius: 30px;

                    opacity: 0;
                }
                .tab-bar {
                    position: absolute;
                    left: 50%;
                    top: 89%;
                    width: 17%;
                    height: 0.9%;
                    background: #efefef;
                    transform: translateX(-50%);
                    border-radius: 30px;
                    mix-blend-mode: hard-light;

                    opacity: 0;
                }
            }
            &.on {
                .app-screen {
                    top: 8.8%;
                    width: 12%;
                    min-width: 228px;
                    height: 81%;
                    border-radius: 30px;

                    .screen-img {
                        opacity: 0;
                    }
                }
                .img-container {
                    .camera,
                    .tab-bar {
                        opacity: 1;
                    }
                }
            }
        }

        .at2 {
            min-height: 800px;
            background: url(../img/renew/labtop_screen.webp) no-repeat right 32px bottom 0 / 66%;
        }

        .description-wrap {
            position: relative;
            padding-top: 120px;

            .project-info {
                margin-bottom: 40px;
                @include body-text;
                dt {
                    color: #fff;
                }
                dd {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    color: $gray400;
                }
                dd + dt {
                    margin-top: 20px;
                }
            }
            .app-icon {
                margin-bottom: 20px;
                width: 60px;
                border-radius: 12px;
            }
        }
    }

    //history
    #history {
        justify-content: flex-start;
        padding-bottom: 0;
        h2 {
            text-align: left;
            color: $gray100;
        }

        .container {
            height: 300vh;
        }
        #history-wrap {
            position: sticky;
            top: 40px;
            left: 0;
            height: 100vh;
        }
        .main-line {
            margin: 0 0 24px;
            @include second-title;
            line-height: 1.25;
            font-weight: 500;
            color: #fff;
            .text-highlight {
                font-weight: bold;
                @include text-gradient;
            }
        }
        .sub-line {
            margin: 0 0 40px;
            @include sub-title;
            color: $gray100;
        }

        #history-content {
            position: relative;
            width: 300%;
            display: flex;
            gap: 20px;
            perspective: 1000px;
            article {
                flex: 0 0 25%;
                padding: 40px;
                border-radius: 24px;
                background: rgba(0, 0, 0, 1);

                img {
                    display: block;
                    margin: 0 auto;
                }
            }
        }
        .history-progress {
            z-index: 1000;
            position: fixed;
            left: 50%;
            bottom: 40px;
            transform: translateX(-50%) scaleY(0);
            width: 400px;
            height: 80px;
            perspective: 1000px;

            opacity: 0;
            transition: transform 0.5s ease;

            .inner {
                background: rgba(255, 255, 255, 0.2);
                border-radius: 8px;
                -webkit-backdrop-filter: saturate(180%) blur(20px);
                backdrop-filter: saturate(180%) blur(20px);

                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
                padding: 20px 40px;
            }
            .progress-bar {
                width: 100%;
                height: 6px;
                border-radius: 4px;
                background: rgba(0, 0, 0, 0.5);
                overflow: hidden;
                .progress-value {
                    width: 0;
                    height: 100%;
                    background: #fff;
                }
            }

            &.active {
                transform: translateX(-50%) scaleY(1);
                opacity: 1;
            }
        }
    }

    //core competencies
    #core {
        .flex-row {
            margin: 0 -10px;
            gap: 20px 0;
            // align-items: stretch;
            flex-wrap: wrap;
        }
        .flex-row > .col {
            padding-left: 10px;
            padding-right: 10px;
            perspective: 2000px;
        }
        .summary-wrap {
            perspective: 2000px;
            opacity: 0;
            transform: translateY(-10px);
            transition: all 0.3s ease-in;
        }
        &.active .summary-wrap {
            opacity: 1;
            transform: translateY(0px);
        }
        .skill-dl {
            position: relative;
            overflow: hidden;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 12px;
            padding: 20px 24px 24px;
            height: 100%;
            align-items: start;
            border-radius: 20px;
            background: #050505;
            cursor: default;

            perspective: 1000px;
            overflow: hidden;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: -100%;
                transition: all 0.2s ease-in-out;
                background-color: #478bff;
                background-image: linear-gradient(145deg, #00ffff, #478bff);
                mix-blend-mode: soft-light;
                border-radius: 20px;
                opacity: 0;
            }
            &:hover::after {
                top: 0%;
                left: 0%;
                opacity: 1;
            }

            dt {
                grid-column: 1 / -1;
                @include sub-title;
                font-weight: 600;
            }
            dd {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 80px;
                padding: 8px;
                text-align: center;
                border: 1px solid rgb(255 255 255 / 20%);
                border-radius: 4px;
                flex-flow: column;
                gap: 8px;
                transition: all 0.05s ease;

                &::after {
                    content: "";
                    display: block;
                    height: 1px;
                    border-top: 5px dotted #478bff;
                }
                &[data-level="1"]::after {
                    width: 6px;
                    opacity: 0.5;
                    filter: saturate(0);
                }
                &[data-level="2"]::after {
                    width: 16px;
                    opacity: 0.75;
                    filter: saturate(0.5);
                }
                &[data-level="3"]::after {
                    width: 24px;
                    opacity: 1;
                }
                span {
                    z-index: 10;
                    position: relative;
                    @include small-text;
                    color: $gray50;
                    font-weight: 200;
                    line-height: 1.2;
                }
                &:hover {
                    transform: translateZ(30px);
                }
            }
            &:hover dd {
                transform: translateZ(10px);
            }
        }
        .level-li {
            margin-top: 20px;
            padding-left: 24px;
            // width: fit-content;
            // margin-left: auto;

            li {
                display: flex;
                align-items: center;

                gap: 12px;
                @include small-text;
                color: $gray100;

                .circle {
                    display: block;
                    width: 24px;

                    &::after {
                        content: "";
                        display: block;
                        height: 1px;
                        border-top: 5px dotted #478bff;
                    }
                    &[data-level="1"]::after {
                        width: 6px;
                        opacity: 0.5;
                        filter: saturate(0);
                    }
                    &[data-level="2"]::after {
                        width: 16px;
                        opacity: 0.75;
                        filter: saturate(0.5);
                    }
                    &[data-level="3"]::after {
                        width: 24px;
                        opacity: 1;
                    }
                }
            }
        }
    }

    //project
    #project {
        .project-wrap {
            .inner {
                position: relative;
                display: flex;
                ul {
                    width: 33.33%;
                    flex-basis: 33.33%;

                    li {
                        width: fit-content;
                        display: flex;
                        align-items: center;
                        line-height: 2;
                        @include sub-title;
                        color: $gray600;
                        border-bottom: 1px solid transparent;
                        cursor: pointer;
                        gap: 4px;
                        .icon {
                            height: 1em;
                        }
                        &.active {
                            color: #fff;
                        }
                    }
                }
            }
            .img-wrap {
                z-index: 0;
                opacity: 1;
                position: absolute;
                right: 0;
                top: 0%;
                width: 66.6%;
                height: 100%;
            }
            .bg {
                height: 100%;
                position: relative;
                perspective: 1000px;
                padding: 0 20px;
                overflow-y: auto;
                // transition: all 0.3s ease-in-out;

                a {
                    position: relative;
                    z-index: 0;
                    transition: all 0.5s ease;
                    filter: brightness(0.5);
                    transform: translateZ(-40px);
                    display: block;

                    img {
                        display: block;
                        width: 100%;
                        object-fit: contain;
                        object-position: left top;
                        aspect-ratio: 700 / 438;
                    }
                    &.active {
                        z-index: 10;
                        display: block;
                        transform: translateZ(0px);
                        filter: brightness(1);
                        box-shadow: 0 0 20px rgba(0, 0, 0, 1);

                        .img {
                            background: #fff;
                        }
                    }
                }
            }
        }
        .info-text {
            margin-top: 20px;
            padding-right: 20px;
            text-align: right;
            color: $gray600;
            @include small-text;
            .name {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 4px;
                @include body-text;
                // font-weight: bold;
                color: $gray300;
            }
            .caption {
                margin-top: 16px;
                @include caption;
            }
        }
    }

    //personality
    #personality {
        overflow: hidden;
        .main-quote {
            line-height: 1.2;
            .emp {
                @include text-gradient;
                font-weight: bold;
            }
        }
    }
    .personality-wrap {
        align-items: center;
        .icon-box {
            position: relative;
            &::before {
                content: "";
                z-index: -1;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                width: 100vw;
                height: 550px;
                background: #1750ac;
                box-shadow: 0 0 20px 20px rgb(0 0 0 /20%);
            }
        }
    }
    .personality-li {
        padding-left: 4px;
        li {
            width: fit-content;
            line-height: 2;
            @include sub-title;
            color: $gray600;
            transition: all 0.3s ease-in-out;
            &.active {
                color: #fff;
            }
        }
    }

    .personality-icon {
        position: relative;
        width: 550px;
        height: 550px;
        border-radius: 50%;
        background: #1750ac;
        li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: absolute;
            right: -50%;
            top: 0;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            &.active {
                right: 0%;
                opacity: 1;
                span {
                    animation-duration: 1.5s;
                }
                .clock {
                    animation-name: clock;
                }
                .process {
                    animation-name: process;
                }
                .handshake {
                    animation-name: handshake;
                }
                .book {
                    animation-name: book;
                }
            }
        }
        .material-symbols-outlined {
            font-size: 275px;
            color: #fff;
            &.clock {
                transform-origin: center bottom;
            }
        }
    }

    @keyframes clock {
        0% {
            transform: rotate(-5deg);
        }
        12.5% {
            transform: rotate(5deg);
        }
        25% {
            transform: rotate(-5deg);
        }
        37.5% {
            transform: rotate(5deg);
        }
        50% {
            transform: rotate(-5deg);
        }
        62.5% {
            transform: rotate(5deg);
        }
        75% {
            transform: rotate(-5deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    @keyframes process {
        0% {
            transform: rotate(0deg);
        }
        10% {
            transform: rotate(90deg);
        }
        25% {
            transform: rotate(90deg);
        }
        35% {
            transform: rotate(180deg);
        }
        50% {
            transform: rotate(180deg);
        }
        60% {
            transform: rotate(270deg);
        }
        75% {
            transform: rotate(270deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes handshake {
        0% {
            transform: translateY(0);
        }
        14.3% {
            transform: translateY(-5px);
        }
        28.6% {
            transform: translateY(5px);
        }
        42.9% {
            transform: translateY(-5px);
        }
        57.2% {
            transform: translateY(5px);
        }
        71.5% {
            transform: translateY(-5px);
        }
        85.8% {
            transform: translateY(5px);
        }
        100% {
            transform: translateY(0);
        }
    }
    @keyframes book {
        0% {
            transform: translateY(0);
        }
        33% {
            transform: translateY(-15px);
        }
        66% {
            transform: translateY(15px);
        }
        100% {
            transform: translateY(0);
        }
    }

    /* contact */
    #contact {
        position: relative;
        margin-bottom: 52.4px;
        padding-top: 180px;
        .container {
            z-index: 10;
            position: relative;
        }
        .main-quote {
            margin-bottom: 80px;
            text-align: center;
            line-height: 1.2;
            .emp {
                @include text-gradient;
            }
        }

        .profile-img {
            position: relative;
            width: 300px;
            height: 300px;
            margin: 0 auto 80px;
            padding: 1px;
            animation-duration: 1.5s;
            animation-timing-function: ease-in;
            animation-fill-mode: forwards;

            &::after,
            &::before,
            .aura {
                content: "";
                z-index: 0;
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                filter: blur(20px);
                background: linear-gradient(327deg, #f7426f, #ffe260, #14ebd2, #478bff);
            }
            &::after {
                z-index: 1;
                filter: blur(6px);
                background: linear-gradient(327deg, #5b0c20, #fff, #055d53, #123d86);
                animation-name: rotateBg;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                mix-blend-mode: hard-light;
            }
            @keyframes rotateBg {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            .inner {
                z-index: 10;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                overflow: hidden;
                width: 100%;
                height: 100%;
            }
            img {
                display: block;
                aspect-ratio: 1 / 1;
                max-width: none;
                object-fit: cover;
                width: 330px;
                max-width: 100%;
                // height: 330px;
            }
        }

        .contact-li {
            margin-bottom: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            li {
                padding: 0 15px;
                font-size: 20px;
                .button {
                    position: relative;
                    display: flex;
                    padding: 0 28px;
                    height: 40px;
                    justify-content: center;
                    align-items: center;
                    background: transparent;
                    border: 0;
                    cursor: pointer;

                    border-radius: 40px;
                    @include sub-title;
                    color: $primary;

                    &::before,
                    .bg {
                        content: "";
                        display: block;
                        background: $primary;
                        z-index: 11;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 40px;
                        height: 40px;
                        border-radius: 40px;
                        mix-blend-mode: plus-lighter;
                        transform: translate(-50%, -50%) scale(0);
                    }
                    &.active .bg {
                        animation-name: button-hover;
                        animation-duration: 0.4s;
                        animation-fill-mode: forwards;
                    }
                    &.out::before {
                        animation-direction: reverse;
                        animation-name: button-hover;
                        animation-duration: 0.4s;
                        animation-fill-mode: forwards;
                    }
                    .value {
                        position: relative;
                        z-index: 10;
                    }
                }
            }
        }
        @keyframes button-hover {
            0% {
                margin-top: 40px;
                transform: translate(-50%, -50%) scale(0);
                width: 40px;
            }
            30% {
                margin-top: 0px;
                transform: translate(-50%, -50%) scale(1);
                width: 40px;
            }
            100% {
                transform: translate(-50%, -50%) scale(1);
                width: 100%;
            }
        }
        .description {
            @include body-text;
            text-align: center;
            color: $gray200;
        }

        .star-container {
            z-index: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
        .shooting-star {
            z-index: 0;
            position: absolute;
            width: 3px;
            height: 80px;
            background: linear-gradient(-45deg, #ffffff, #ebd64a, #000);
            transform: rotate(45deg);
            opacity: 0;
            animation: shooting 2s linear infinite;
        }
        @keyframes shooting {
            from {
                opacity: 0.3;
                transform: translate(20vw, -20vh) rotate(45deg);
            }
            to {
                opacity: 0;
                transform: translate(-20vw, 70vh) rotate(45deg);
            }
        }
    }

    /* footer */
    #footer {
        z-index: 0;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 15px 0;
        font-size: 14px;
        color: $gray600;
        background: #161617;
        .copy {
            text-align: right;
        }
    }

    //toast message
    #toast-message {
        z-index: 1000;
        height: 60px;
        position: fixed;
        left: 50%;
        bottom: 10vh;
        width: fit-content;
        max-width: 100%;
        transform: translateX(-50%);
        border-radius: 4px;

        overflow: hidden;

        .inner {
            z-index: 1000;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            overflow: hidden;
            white-space: nowrap;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
            visibility: hidden;
        }

        .message {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            @include sub-title;
            color: $gray50;
            gap: 8px;
            transition: all 0.4s ease-in-out 0.4s;
            opacity: 0;
        }

        &::before,
        &::after {
            content: "";
            z-index: 900;
            position: absolute;
            left: 50%;
            top: 50%;
            display: block;
            width: 0;
            height: 0;
            background: $primary900;
            transform: translate(-50%, -50%);
            border-radius: 4px;
        }

        &.active {
            .inner {
                width: 100%;
                height: 100%;
                padding: 0 80px;
                visibility: visible;

                .message {
                    animation-name: message-show;
                    animation-duration: 0.6s;
                    animation-fill-mode: forwards;
                    animation-delay: 0.4s;
                }
            }

            &::before {
                animation-name: message-bg-show;
                animation-duration: 0.6s;
                animation-fill-mode: forwards;
            }
        }
        &.fade {
            &::after {
                animation-direction: reverse;
                animation-name: message-bg-show;
                animation-duration: 0.6s;
                animation-fill-mode: forwards;
            }
        }
    }

    @keyframes message-show {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes message-bg-show {
        0% {
            margin-top: 60px;
            width: 0;
            height: 0;
        }
        30% {
            margin-top: 0;
            width: 60px;
            height: 60px;
        }
        100% {
            width: 100%;
            height: 60px;
        }
    }

    //floating
    #floating {
        z-index: 2000;
        position: fixed;
        right: 20px;
        bottom: 30px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        gap: 8px;

        border: 0;
        background: $primary900;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

        transform: scale(0);
        transition: transform 0.3s ease-in-out;

        &.active {
            transform: scale(1);
        }
        .bar {
            width: 34px;
            height: 4px;
            border-radius: 4px;
            background: #fff;
            transition: all 0.3s ease;
        }
        &.off {
            z-index: 2001;
            opacity: 0.6;
            background: transparent;
            box-shadow: none;

            &:hover {
                opacity: 1;
            }

            .b1 {
                transform-origin: left center;
                transform: rotate(45deg);
            }
            .b2 {
                opacity: 0;
            }
            .b3 {
                transform-origin: left center;
                transform: rotate(-45deg);
            }
        }
    }

    #side-menu {
        z-index: 2000;
        position: fixed;
        right: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        padding: 80px;
        background: rgba(0, 0, 0, 0.77);
        backdrop-filter: blur(4px);
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        gap: 80px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        &.on {
            visibility: visible;
            opacity: 1;
        }

        .menu-list {
            padding-top: 40px;
            display: flex;
            flex-flow: column;
            gap: 20px;

            button {
                display: inline-block;
                @include second-title;
                color: $gray300;
                border: 0;
                cursor: pointer;
                text-transform: uppercase;
                background: transparent;
                font-weight: bold;
                &:hover {
                    color: #fff;
                }
            }
        }

        .icon-menu {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 40px;
            .button {
                color: #fff;
                background: transparent;
                border: 0;
                opacity: 0.6;
                &:hover {
                    opacity: 1;
                }
            }
            .icon {
                font-size: 30px;
                width: 30px;
                font-variation-settings: "FILL" 1;
            }
        }
    }
}

/* responsive */
@media (max-width: 999px) {
    .renew {
        /* loading */
        #loading {
            font-size: 50px;
        }
        #loading #phase1 span {
            font-size: 34px;
        }
        #loading .main-text .sup-text {
            top: -40px;
            font-size: 16px;
        }
        #loading #phase5 .l1 span {
            font-size: 28px;
        }
        #loading #phase5 .l2 {
            margin-top: -14px;
            text-align: center;
        }
        #loading #phase5 .l2 span {
            font-size: 56px;
            line-height: 1.2;
            height: 1.2em;
        }
        #loading-close {
            right: 20px;
            bottom: 20px;
            width: 80px;
            height: 80px;
            font-size: 14px;
        }
        /* common */
        // body,
        // html {
        //     width: 100vw;
        //     overflow-x: hidden;
        // }
        .flex-row {
            flex-wrap: wrap;
        }
        .flex-row > .col {
            flex-basis: 100%;
        }
        .visible-xs {
            display: block;
        }
        .hidden-xs {
            display: none;
        }

        /* main-content */
        .main-content section {
            padding: 60px 0;
            h2 {
                margin-bottom: 28px;
            }
            h2 + p {
                margin-top: -20px;
                margin-bottom: 28px;
                @include body-text;
            }
        }
        .main-content .main-title {
            margin-bottom: 20px;
            font-size: 40px;
        }
        .main-content [data-acive="fadeup"] {
            transform: translateY(0);
        }

        //key-visual
        #key-visual {
            height: 95vh;
            padding: 0;

            .cowork-text,
            .job-text {
                position: fixed;
                writing-mode: initial;
                transform: translateX(-50%);
                @include sub-title;
            }
            .cowork-text {
                top: 28px;
                bottom: auto;
                left: 50%;
            }
            .job-text {
                top: auto;
                bottom: 40px;
                right: auto;
                left: 50%;
            }

            .visual-wrap {
                .txt-box {
                    h2 {
                        margin-top: 0px;
                        @include hero;
                        small {
                            display: block;
                            font-weight: 400;
                            @include second-title;
                            color: #fff;
                        }
                    }
                }
            }
            .canvas-wrap {
                position: relative;
                aspect-ratio: 1/1;
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        //history
        #history {
            .container {
                height: auto;
            }
            #history-wrap {
                position: static;
                height: auto;
            }
            #history-content {
                width: auto;
                flex-wrap: wrap;
                article {
                    flex-basis: 100%;
                    padding: 24px;
                }
            }

            .main-line {
                margin-bottom: 8px;
                @include content-title;
                line-height: 1.4;
            }
            .sub-line {
                margin-bottom: 20px;
                @include small-text;
                line-height: 1.4;
            }
        }

        .personality-li {
            margin-bottom: 40px;
            li {
                display: none;
                line-height: 1.4;
                &.active {
                    display: block;
                }
            }
        }
        .personality-icon {
            margin: 0 auto;
            width: 80vw;
            height: 80vw;
            .material-symbols-outlined {
                font-size: 35vw;
            }
        }
        .personality-wrap .icon-box:before {
            width: 80vw;
            height: 80vw;
        }

        #example {
            .mobile-wrap {
                .img-container {
                    .sample-screen {
                        border-radius: 20px;
                    }
                }
            }
            .description-wrap {
                padding-top: 60px;
                .project-info {
                    dd:first-of-type {
                        display: inline;
                    }
                }
            }
            .at2 {
                background-size: 80%;
                padding-bottom: 20vw;
            }
        }

        #project {
            .project-wrap {
                .inner {
                    flex-flow: column-reverse;
                    flex-wrap: wrap;
                    .project-li {
                        position: relative;
                        width: 100%;
                        height: calc(6em + 16px);
                        flex-basis: calc(6em + 16px);
                        overflow-y: scroll;
                        padding: 8px;
                        border: 1px solid $gray900;
                        border-radius: 12px;
                        // scrollbar-width: thin;
                        li {
                            @include body-text;
                        }
                        // &::-webkit-scrollbar {
                        //     display: block;
                        // }
                        // &::-webkit-scrollbar-thumb {
                        //     background-color: $gray600;
                        // }
                        // &::-webkit-scrollbar-track {
                        //     background-color: $gray900;
                        // }
                    }

                    &::after {
                        content: "\ead0";
                        position: absolute;
                        right: 6px;
                        bottom: 2px;
                        font-family: "material symbols outlined";
                        color: $gray400;
                    }
                }
                .img-wrap {
                    position: relative;
                    width: 100%;
                    flex-basis: 100%;
                }
                .bg {
                    margin-bottom: 12px;
                    padding: 0 8px;
                    height: 57vw;
                    flex-basis: 57vw;
                    a {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
            .info-text {
                margin-top: 0;
                margin-bottom: 20px;
                text-align: left;
                .name {
                    justify-content: flex-start;
                }
            }
        }
        #core {
            .skill-dl::after {
                display: none;
            }
        }

        #contact {
            margin-bottom: 71px;
            padding-top: 60px;
            padding-bottom: 40px;
            .main-title {
                margin-bottom: 30px;
            }
            .contact-li {
                flex-wrap: wrap;
                li {
                    font-size: 16px;
                    padding: 0 4px;
                    .button {
                        padding: 0 8px;
                        @include small-text;
                    }
                }
            }

            .profile-img {
                width: 66vw;
                height: 66vw;
                max-width: 330px;
                max-height: 330px;
            }
            .description {
                @include caption;
            }
        }

        #footer {
            max-width: 100vw;
            .copy {
                text-align: left;
                @include caption;
            }
        }

        #floating {
            width: 60px;
            height: 60px;
            gap: 6px;
            .bar {
                width: 26px;
                height: 3px;
            }
        }

        #side-menu {
            padding: 0 40px;
            .menu-list button {
                @include content-title;
            }
        }
        #toast-message {
            width: calc(100vw - 16px);
            height: auto;
            &.active .inner {
                padding: 20px;
                white-space: wrap;
                justify-content: center;
            }
            .message {
                @include body-text;
                gap: 16px;
                .value {
                    text-align: left;
                    line-height: 1.2;
                }
            }
        }
    }
}

/* responsive -tablet */
@media (max-width: 999px) and (min-width: 768px) {
    .renew {
        .summary-wrap .skill-dl dd:before {
            height: 520%;
            width: 160%;
            margin-top: -20%;
            margin-left: 20%;
        }
    }
}
