@charset "utf-8";
.home {
    overflow: hidden;
    &.on {
        overflow: initial;
    }
    &:before {
        content: "";
        opacity: 0;
        z-index: 1020;
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        background: rgba(29, 29, 31, 0.72);
        width: 100%;
        height: 0;
        transition: opacity 0.4s ease-in-out;
    }
    &.blur {
        overflow: hidden;
        &::before {
            opacity: 1;
            height: 100vh;
        }
    }
    .container.wide {
        width: 1200px;
    }

    /* loading */
    #loading {
        z-index: 2000;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        font-size: 90px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        background: #000;
        white-space: nowrap;
        perspective: 2000px;
    }
    #loading > div {
        visibility: hidden;
        z-index: 1900;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
    }
    #loading > div.active {
        z-index: 2000;
        visibility: visible;
    }
    #loading > div.dark {
        color: #f5f5f7;
        background: #000;
    }
    #loading > div.light {
        color: #161617;
        background: #fff;
    }

    /* loading-phase1 */
    #loading #phase1 p {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.5s ease;
    }
    #loading #phase1 span {
        opacity: 0;
    }
    #loading #phase1 p.active span {
        opacity: 1;
    }
    #loading #phase1 .l1 .t1 {
        transition-delay: 0s;
    }
    #loading #phase1 .l1 .t2 {
        transition-delay: 0.7s;
    }
    #loading #phase1 .l1 .t3 {
        transition-delay: 0.9s;
    }
    #loading #phase1 .l1 .t4 {
        transition-delay: 1.1s;
    }
    #loading .main-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /* loading-phase2~4 */
    #loading .trait .main-text {
        height: 1em;
    }
    #loading .main-text .sup-text {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: -50px;
        transform: translateX(-50%);
        display: block;
        font-size: 24px;
        letter-spacing: -1em;
        margin-left: -1em;
    }
    #loading .main-text.active .sup-text {
        opacity: 1;
        letter-spacing: 1.5em;
        margin-left: 1.5em;
        transition: letter-spacing 0.7s ease-out;
    }
    #loading .trait .main-text span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) scalex(1);
        letter-spacing: -0.03em;
        line-height: 1;
        mix-blend-mode: multiply;
        transition: all 0.7s ease;
    }
    #loading .trait .main-text.active span {
        transform: translateX(-50%) scalex(1.3);
    }
    #loading .main-text .text-c {
        color: #00aeef;
    }
    #loading .main-text .text-m {
        color: #d33d76;
    }
    #loading .main-text .text-y {
        color: #fee716;
    }
    #loading .main-text.active .text-c {
        margin-left: 1.15em;
    }
    #loading .main-text.active .text-y {
        margin-left: -1.15em;
    }

    /* loading-phase5 */
    #loading #phase5 p {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    #loading #phase5 .l1 {
        height: 1em;
        overflow: hidden;
        transition: all 0.5s ease;
    }
    #loading #phase5 .l1 span {
        display: inline-block;
        line-height: 1;
        vertical-align: top;
        transition: all 1s ease;
    }

    #loading #phase5 .l1 .t1 {
        transition-delay: 0s;
    }
    #loading #phase5 .l1 .t2 {
        transition-delay: 0.5s;
    }
    #loading #phase5 .l1 .t3 {
        transition-delay: 0.9s;
    }

    #loading #phase5 .l2 {
        width: 1200px;
        max-width: 100%;
        text-align: left;
        padding: 0 15px;
    }
    #loading #phase5 .l2 span {
        opacity: 0;
        display: block;
        line-height: 1;
        font-size: 120px;
        font-weight: bold;
        letter-spacing: -0.04em;
        transition: all 0.5s ease;
    }
    #loading #phase5 .l2 .t1 {
        transition-delay: 0s;
    }
    #loading #phase5 .l2 .t2 {
        transition-delay: 0.5s;
    }
    #loading #phase5 .l2 .t3 {
        transition-delay: 1s;
    }
    #loading #phase5 .l2.active span {
        opacity: 1;
    }

    #loading-close {
        opacity: 0;
        display: flex;
        z-index: 2020;
        position: absolute;
        right: 50px;
        bottom: 50px;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        font-size: 16px;
        font-weight: bold;
        color: #161617;
        background: #fee716;
        overflow: hidden;
        transition: all 0.3s ease-in;
    }
    #loading-close:hover {
        animation-name: fliping-btn;
        animation-duration: 1s;
        animation-fill-mode: both;
    }
    #loading-close.active {
        opacity: 1;
    }
    @keyframes fliping-btn {
        0% {
            transform: rotateY(0);
        }
        25% {
            transform: rotateY(-20deg);
        }
        50% {
            transform: rotateY(20deg);
        }
        75% {
            transform: rotateY(-20deg);
        }
        100% {
            transform: rotateY(0);
        }
    }
    /* navigation */
    #navi {
        z-index: 1060;
        position: fixed;
        left: 0;
        top: -56px;
        width: 100%;
        padding: 15px 0;
        height: 55px;
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        background: rgba(29, 29, 31, 0.72);
        border-bottom: 1px solid #111;
        overflow: hidden;
        transition: all 0.8s ease-in-out;
    }
    &.on #navi {
        top: 0;
    }

    #navi.active {
        height: 350px;
        background: #161617;
        box-shadow: 20px 0 20px 20px rgb(0 0 0 / 20%);
        transition: all 0.4s ease-in-out;
    }

    #navi.active:after {
        content: "";
        z-index: 1040;
        display: block;
        position: absolute;
        left: 0;
        top: 55px;
        width: 100%;
        height: 1px;
        background: rgb(255 255 255 / 10%);
    }
    #navi .flex-row {
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1060;
    }
    #menu-name {
        position: relative;
        display: flex;
        font-size: 24px;
        align-items: center;
        line-height: 1;
    }
    #menu-name .curr-name {
        font-weight: 600;
        color: #f5f5f7;
        cursor: pointer;
        letter-spacing: -0.02em;
        text-transform: uppercase;
    }
    #menu-name .icon {
        cursor: pointer;
        vertical-align: initial;
    }
    #navi.active #menu-name .icon {
        transform: rotateX(180deg);
    }
    .menu-list {
        z-index: 1040;
        position: absolute;
        left: 0;
        top: 50px;
        text-transform: uppercase;
        transition: all 0.3s ease-in;
        opacity: 0;
    }
    #navi.active .menu-list {
        opacity: 1;
    }
    #navi .menu-list li {
        opacity: 0;
        transition: all 0.3s ease-in;
    }
    #navi .menu-list li:nth-child(1) {
        transition-delay: 0.1s;
    }
    #navi .menu-list li:nth-child(2) {
        transition-delay: 0.15s;
    }
    #navi .menu-list li:nth-child(3) {
        transition-delay: 0.2s;
    }
    #navi .menu-list li:nth-child(4) {
        transition-delay: 0.25s;
    }
    #navi .menu-list li:nth-child(5) {
        transition-delay: 0.3s;
    }
    #navi .menu-list li:nth-child(6) {
        transition-delay: 0.35s;
    }
    #navi .menu-list li:nth-child(7) {
        transition-delay: 0.4s;
    }
    #navi .menu-list li:nth-child(8) {
        transition-delay: 0.45s;
    }
    #navi.active .menu-list li {
        opacity: 1;
    }
    #navi .menu-list li a {
        position: relative;
        display: block;
        width: fit-content;
        padding: 9px 0px;
        font-size: 18px;
        color: #c1c1c1;
        font-weight: 500;
        overflow: hidden;
    }
    #navi.active .menu-list li a:hover {
        color: #f5f5f7;
    }
    #navi.active .menu-list li a:before {
        content: "";
        position: absolute;
        left: -110%;
        bottom: 0px;
        display: block;
        width: 100%;
        height: 1px;
        background: #c1c1c1;
        transition: all 0.3s ease-in;
    }
    #navi.active .menu-list li a:hover:before {
        left: 0;
    }
    .top-link {
        display: flex;
        white-space: nowrap;
        justify-content: flex-end;
    }
    .top-link li {
        position: relative;
        margin: 0;
        padding: 0 10px;
        line-height: 1;
    }
    .top-link li:last-child {
        padding-right: 0;
    }
    .top-link li + li:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translatey(-50%);
        margin-top: 2px;
        width: 1px;
        height: 15px;
        background: #333;
    }
    .top-link li a {
        font-size: 12px;
        color: #f5f5f7;
        font-weight: 500;
    }

    /* scollbar */
    #nav-scroll {
        z-index: 1000;
        position: fixed;
        right: 20px;
        top: 50%;
        transform: translateY(-50%) scale(0.85);
        display: flex;
        flex-flow: column;
        align-items: center;
        opacity: 0;
    }
    &.on #nav-scroll {
        animation-name: navi;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
    }
    @keyframes navi {
        0% {
            opacity: 0;
            transform: translateY(-50%) scale(0.85);
        }
        70% {
            opacity: 1;
            transform: translateY(-50%) scale(1.03);
        }
        100% {
            opacity: 1;
            transform: translateY(-50%) scale(1);
        }
    }
    #scroll-bar {
        position: relative;
        width: 14px;
        height: 150px;
        border-radius: 50px;
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        background: rgba(129, 129, 133, 0.72);
        box-shadow: 0 0 5px 5px rgb(0 0 0 / 5%);
        transition: all 0.1s ease-in-out;
    }
    #scroll-bar .inner {
        position: absolute;
        left: 50%;
        top: 2%;
        width: 1px;
        height: calc(96% - 8px);
        margin-left: -1px;
    }

    #scroll-bar .current-indicator {
        display: block;
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translateX(-50%);
        width: 8px;
        height: 8px;
        background: #f5f5f7;
        opacity: 0.8;
        border-radius: 50%;
        transition: all 0.1s ease-in-out;
    }

    #scroll-top {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #fff;
        font-weight: bold;
        font-size: 8px;
        background: #2997ff;
        box-shadow: 0 0 5px 5px rgb(0 0 0 / 5%);
    }
    #scroll-top:before {
        content: "";
        display: block;
        border-bottom: 5px solid #fff;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
    }

    /* main-content */
    .main-content section {
        z-index: 10;
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        min-height: 100vh;
        padding: 120px 0;
        background: #161617;
        border-bottom: 1px solid rgb(255 255 255 / 10%);
    }
    .main-content .main-title {
        font-weight: bold;
        font-size: 54px;
        line-height: 1.3;
        letter-spacing: -0.03em;
        color: #cecece;
    }
    .main-content .main-title .emp {
        position: relative;
        display: inline-block;
        color: transparent;
        background-color: #86868b;
        background-clip: text;
        -webkit-background-clip: text;
        overflow: hidden;
        vertical-align: bottom;
    }
    .main-content .main-title .emp:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        background-color: #23e4ca;
        background-image: linear-gradient(-45deg, #23e4ca 0%, #fffb7d 100%);
    }
    .active .main-title .emp:before {
        animation-name: textEmpBg;
        animation-duration: 0.8s;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
    }
    .active .main-title .emp {
        animation-name: textEmp;
        animation-duration: 0.2s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.4s;
    }
    @keyframes textEmp {
        0% {
            background-color: #86868b;
        }
        90% {
            background-color: #86868b;
        }
        100% {
            background-clip: text;
            -webkit-background-clip: text;
            background-color: #23e4ca;
            background-image: linear-gradient(-45deg, #23e4ca 0%, #fffb7d 100%);
        }
    }
    @keyframes textEmpBg {
        0% {
            width: 0%;
            left: 0;
        }
        65% {
            width: 200%;
            left: 0%;
        }
        99% {
            opacity: 1;
            width: 200%;
            left: 205%;
        }
        100% {
            opacity: 0;
            width: 200%;
            left: 205%;
        }
    }
    .main-content [data-acive="fadeup"] {
        transform: translateY(50px);
        opacity: 0.5;
        transition: all 0.3s ease-out;
    }
    .main-content section.active [data-acive="fadeup"] {
        transform: translateY(0);
        opacity: 1;
    }
    .main-content [data-acive="fadeleft"] {
        transform: translateX(-50px);
        opacity: 0;
        transition: all 0.3s ease-out;
    }
    .main-content section.active [data-acive="fadeleft"] {
        transform: translateY(0);
        opacity: 1;
    }
    .main-content [data-active-idx="1"] {
        transition-delay: 0.3s;
    }
    .main-content [data-active-idx="2"] {
        transition-delay: 0.4s;
    }
    .main-content [data-active-idx="3"] {
        transition-delay: 0.5s;
    }
    .main-content [data-active-idx="4"] {
        transition-delay: 0.6s;
    }

    /* main-view */
    #main-view {
        z-index: 1;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    #main-view:before {
        content: "";
        display: block;
        z-index: 10;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    #main-view #main_bg {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        min-width: 100%;
        min-height: 110%;
        object-fit: cover;
        pointer-events: none;
    }
    #main_bg::-moz-media-controls,
    #main_bg::-webkit-media-controls {
        display: none !important;
    }
    #main-view .container {
        z-index: 50;
        position: fixed;
    }
    #main-view .middle-title span {
        display: block;
        height: 1em;
        overflow: hidden;
        padding-top: 1.1em;
        font-size: 120px;
        font-weight: bold;
        line-height: 1;
        color: transparent;
        background-color: #23e4ca;
        background-image: linear-gradient(-45deg, #23e4ca 0%, #fffb7d 100%);
        background-clip: text;
        -webkit-background-clip: text;
        text-transform: uppercase;
        letter-spacing: -0.04em;
        transition: all 0.7s ease-in-out;
    }
    #main-view .middle-title .t1 {
        transition-delay: 0s;
    }
    #main-view .middle-title .t2 {
        transition-delay: 0.3s;
    }
    #main-view .middle-title .t3 {
        transition-delay: 0.6s;
    }
    .active#main-view .middle-title span {
        padding-top: 0;
    }

    #main-view .middle-title span small {
        display: none;
        font-size: 20px;
        letter-spacing: 0;
        font-weight: 500;
        color: #f5f5f7;
    }

    /* intro */
    #intro h3 {
        margin-bottom: 80px;
        text-align: center;
    }
    #intro .flex-row {
        margin: 0 -10px 20px;
        align-items: stretch;
    }
    #intro .flex-row > .col {
        padding-left: 10px;
        padding-right: 10px;
    }
    .summary-wrap dl {
        height: 100%;
        padding: 20px 25px 25px;
        border-radius: 20px;
        background: #050505;
    }
    .summary-wrap dl dt {
        margin-bottom: 5px;
        max-width: fit-content;
        font-size: 20px;
        font-weight: 600;
    }
    .summary-wrap dl dd + dt {
        margin-top: 15px;
    }
    .summary-wrap .skill-dl dd {
        position: relative;
        display: inline-flex;
        margin: 6px 3px;
        justify-content: center;
        align-items: center;
        width: 46%;
        min-height: 60px;
        text-align: center;
        border: 1px solid rgb(255 255 255 / 10%);
        overflow: hidden;
    }
    .summary-wrap .skill-dl dd span {
        z-index: 10;
        position: relative;
        font-size: 13px;
        line-height: 1.2;
    }
    .summary-wrap .skill-dl dd:before,
    .summary-wrap .skill-dl dd:after {
        content: "";
        z-index: 1;
        display: block;
        position: absolute;
        transition: all 0.3s ease-in-out;
    }
    .summary-wrap .skill-dl dd:before {
        left: -50%;
        top: -50%;
        width: 200%;
        height: 300%;
        background: linear-gradient(135deg, #c8eeff, #2fff90, #6cffc9, #2effd5);
        transform: rotate(-45deg);
    }
    .active .summary-wrap .skill-dl dd:before {
        animation: borderAnimation 0.9s linear;
        animation-fill-mode: backwards;
    }

    .summary-wrap .skill-dl dd:nth-of-type(2):before,
    .summary-wrap .skill-dl dd:nth-of-type(3):before {
        animation-delay: 0.3s;
    }
    .summary-wrap .skill-dl dd:nth-of-type(4):before,
    .summary-wrap .skill-dl dd:nth-of-type(5):before {
        animation-delay: 0.6s;
    }
    .summary-wrap .skill-dl dd:nth-of-type(6):before {
        animation-delay: 0.9s;
    }
    .summary-wrap .skill-dl dd:after {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        left: 1px;
        top: 1px;
        background: #050505;
    }
    .summary-wrap dl li {
        position: relative;
        padding-left: 10px;
        font-size: 14px;
        opacity: 0.9;
        line-height: 1.8;
    }
    .summary-wrap dl li:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 12px;
        width: 2px;
        height: 2px;
        background: #333;
    }

    @keyframes borderAnimation {
        0% {
            left: -200%;
            top: -200%;
        }
        100% {
            left: -50%;
            top: -50%;
        }
    }
    /* history */
    #history {
        padding-bottom: 0;
    }
    #history-wrap {
        position: relative;
    }
    #history-wrap .txt-box {
        z-index: 10;
        position: sticky;
        left: 0;
        top: 120px;
        margin-bottom: calc(100vh - 212px);
        width: 100%;
        color: #cecece;
        text-align: center;
        padding-top: 5vh;
    }
    #history .main-line,
    #history .sub-line {
        position: relative;
        height: 1em;
        line-height: 1;
        overflow: hidden;
    }
    #history .main-line span,
    #history .sub-line span {
        position: absolute;
        /* left:0; */
        top: 100%;
        transition: all 0.3s ease-in-out;
        display: block;
        width: 100%;
    }
    #history .main-line span.active,
    #history .sub-line span.active {
        top: 0;
    }
    #history .main-line {
        margin-bottom: 30px;
        font-size: 42px;
        font-weight: bold;
    }
    #history .sub-line {
        font-size: 20px;
    }
    #history .sub-line strong {
        color: #fff;
        font-weight: 600;
    }
    #history-content {
        position: relative;
        margin-top: calc(-100vh + 320px);
    }
    #history-content article {
        position: relative;
        min-height: 100vh;
        overflow: hidden;
        opacity: 0.3;
        background: url(../img/2023ver/history1.png) no-repeat center bottom / 95%;
        filter: grayscale(1);
    }
    #history-content #at1 {
        background-image: url(../img/2023ver/history1.png);
    }
    #history-content #at2 {
        background-image: url(../img/2023ver/history2.png);
    }
    #history-content #at3 {
        background-image: url(../img/2023ver/history3.png);
    }
    #history-content #at4 {
        background-image: url(../img/2023ver/history4.png);
    }

    /* personality */
    #personality {
        overflow: hidden;
    }
    #personality .main-title {
        margin-bottom: 60px;
    }
    .personality-wrap {
        align-items: center;
    }
    .personality-li {
        padding-left: 4px;
    }
    .personality-li li {
        width: fit-content;
        line-height: 2;
        font-size: 20px;
        color: #a1a1a6;
        transition: all 0.3s ease-in-out;
    }
    .personality-li li.active {
        color: #fff;
    }
    .personality-wrap .icon-box {
        position: relative;
    }
    .personality-wrap .icon-box:before {
        content: "";
        z-index: -1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 100vw;
        height: 550px;
        background: #1750ac;
        box-shadow: 0 0 20px 20px rgb(0 0 0 /20%);
    }
    .personality-icon {
        position: relative;
        width: 550px;
        height: 550px;
        border-radius: 50%;
        background: #1750ac;
    }
    .personality-icon li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        right: -50%;
        top: 0;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .personality-icon .active {
        right: 0%;
        opacity: 1;
    }
    .personality-icon span {
        font-size: 275px;
        color: #fff;
    }
    .personality-icon .clock {
        transform-origin: center bottom;
    }
    .personality-icon .active span {
        animation-duration: 1.5s;
    }
    .personality-icon .active .clock {
        animation-name: clock;
    }
    .personality-icon .active .process {
        animation-name: process;
    }
    .personality-icon .active .handshake {
        animation-name: handshake;
    }
    .personality-icon .active .book {
        animation-name: book;
    }

    @keyframes clock {
        0% {
            transform: rotate(-5deg);
        }
        12.5% {
            transform: rotate(5deg);
        }
        25% {
            transform: rotate(-5deg);
        }
        37.5% {
            transform: rotate(5deg);
        }
        50% {
            transform: rotate(-5deg);
        }
        62.5% {
            transform: rotate(5deg);
        }
        75% {
            transform: rotate(-5deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    @keyframes process {
        0% {
            transform: rotate(0deg);
        }
        10% {
            transform: rotate(90deg);
        }
        25% {
            transform: rotate(90deg);
        }
        35% {
            transform: rotate(180deg);
        }
        50% {
            transform: rotate(180deg);
        }
        60% {
            transform: rotate(270deg);
        }
        75% {
            transform: rotate(270deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes handshake {
        0% {
            transform: translateY(0);
        }
        14.3% {
            transform: translateY(-5px);
        }
        28.6% {
            transform: translateY(5px);
        }
        42.9% {
            transform: translateY(-5px);
        }
        57.2% {
            transform: translateY(5px);
        }
        71.5% {
            transform: translateY(-5px);
        }
        85.8% {
            transform: translateY(5px);
        }
        100% {
            transform: translateY(0);
        }
    }
    @keyframes book {
        0% {
            transform: translateY(0);
        }
        33% {
            transform: translateY(-15px);
        }
        66% {
            transform: translateY(15px);
        }
        100% {
            transform: translateY(0);
        }
    }

    #project .sub-title,
    #project .main-title {
        margin-bottom: 15px;
        text-align: center;
    }
    #project .sub-title {
        margin-bottom: 60px;
        font-size: 20px;
        color: #cecece;
    }
    #project .sub-title strong {
        color: #fff;
        font-weight: 600;
    }

    #project .info-text {
        margin-top: 30px;
        text-align: right;
        color: #a1a1a6;
    }
    .project-wrap ul {
        position: relative;
        padding: 50px 40px;
        background: #050505;
        overflow: hidden;
        border-radius: 20px;
    }
    .project-wrap ul li {
        width: fit-content;
        line-height: 2;
        font-size: 18px;
        color: #a1a1a6;
    }
    .project-wrap ul li a {
        color: #a1a1a6;
        border-bottom: 1px solid transparent;
    }
    .project-wrap ul li.active a {
        color: #fff;
    }
    .project-wrap ul li a:hover {
        border-bottom: 1px solid #fff;
    }
    .project-wrap ul .bg {
        content: "";
        z-index: 0;
        opacity: 0.85;
        position: absolute;
        right: -60%;
        top: 50%;
        transform: translateY(-50%) scale(0.97);
        transform-origin: left center;
        width: 100%;
        height: 50%;
        background: url(../img/2023ver/labtop.png) no-repeat left center / contain;
        transition: all 0.3s ease-in-out;
    }
    .project-wrap ul:hover .bg {
        opacity: 1;
        right: -55%;
        transform: translateY(-50%) scale(1);
    }
    .project-wrap ul .bg .img {
        opacity: 0;
        display: block;
        position: absolute;
        left: 12%;
        top: 5.3%;
        width: 46%;
        height: 69%;
        object-fit: cover;
        object-position: left top;
    }
    .project-wrap ul .bg .img.active {
        opacity: 1;
        background: #fff;
    }

    /* example */
    #example .main-title {
        margin-bottom: 60px;
    }
    #major-wrap,
    #major-wrap article {
        position: relative;
    }
    #major-wrap article:not(.example1) {
        margin-top: -30px;
    }
    #major-wrap article img {
        position: relative;
        display: block;
        width: 60%;
        box-shadow: 0 0 10px 10px rgb(0 0 0 /30%);
        filter: brightness(0.5);
    }
    #major-wrap .example1 img {
        left: 20%;
        z-index: 3;
    }
    #major-wrap .example2 img {
        left: -10%;
        z-index: 5;
    }
    #major-wrap .example3 img {
        left: 10%;
        z-index: 3;
    }
    #major-wrap .example4 img {
        left: -20%;
        z-index: 5;
    }

    #major-wrap article .txt-box {
        z-index: 1;
        position: absolute;
        left: 0%;
        bottom: 0;
        opacity: 0;
        padding: 0 30px 60px;
        width: 40%;
        transition: all 0.5s ease;
    }
    #major-wrap .example4 .txt-box {
        padding-bottom: 0;
    }
    #major-wrap .txt-box h5 {
        margin-bottom: 5px;
        font-size: 24px;
        font-weight: 600;
        color: #f5f5f7;
    }

    #major-wrap .txt-box .period {
        margin-bottom: 45px;
    }
    #major-wrap .txt-box .des {
        margin-bottom: 15px;
        color: #a1a1a6;
    }
    #major-wrap .txt-box .des strong {
        color: #f5f5f7;
    }
    #major-wrap .txt-box .more:hover {
        text-decoration: underline;
    }
    #major-wrap .txt-box .more .icon {
        margin-left: -5px;
    }

    #major-wrap .reverse .txt-box {
        left: auto;
        right: 0%;
        padding-left: 0;
    }
    #major-wrap .reverse img {
        margin-left: auto;
    }
    #major-wrap .reverse h5 {
        padding-right: 15px;
        width: fit-content;
    }

    #major-wrap article.active:not(.reverse) .txt-box {
        opacity: 1;
        left: 60%;
    }
    #major-wrap article.active.reverse .txt-box {
        opacity: 1;
        right: 60%;
    }
    /* contact */
    #contact {
        margin-bottom: 52.4px;
        padding-top: 180px;
    }
    #contact .main-title {
        margin-bottom: 45px;
        text-align: center;
    }
    #contact .contact-li {
        margin-bottom: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #contact .contact-li li {
        padding: 0 15px;
        font-size: 20px;
    }
    #contact .contact-li li a:hover {
        text-decoration: underline;
    }
    #contact .contact-li .icon {
        margin-left: -5px;
    }
    .profile-img {
        position: relative;
        width: 300px;
        height: 300px;
        margin: 0 auto;
        padding: 1px;
        animation-duration: 1.5s;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
    }
    .profile-img:before,
    .profile-img:after {
        content: "";
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: linear-gradient(327deg, #4242f7, #ff609b, #a340e4);
        background: linear-gradient(327deg, #f7426f, #ffe260, #14ebd2);
        animation-name: rotateBg;
        animation-duration: 5s;
        animation-iteration-count: infinite;
    }
    .profile-img:before {
        filter: blur(10px);
    }
    .profile-img img {
        z-index: 10;
        display: block;
        position: relative;
        border-radius: 50%;
    }
    @keyframes rotateBg {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /* footer */
    #footer {
        z-index: 0;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 15px 0;
        font-size: 14px;
        color: #86868b;
        background: #161617;
    }
    #footer .copy {
        text-align: right;
    }
}

/* responsive */
@media (max-width: 999px) {
    .home {
        /* loading */
        #loading {
            font-size: 50px;
        }
        #loading #phase1 span {
            font-size: 34px;
        }
        #loading .main-text .sup-text {
            top: -40px;
            font-size: 16px;
        }
        #loading #phase5 .l1 span {
            font-size: 28px;
        }
        #loading #phase5 .l2 {
            margin-top: -14px;
            text-align: center;
        }
        #loading #phase5 .l2 span {
            font-size: 56px;
            line-height: 1.2;
            height: 1.2em;
        }
        #loading-close {
            right: 20px;
            bottom: 20px;
            width: 80px;
            height: 80px;
            font-size: 14px;
        }
        /* common */
        body,
        html {
            width: 100vw;
            overflow-x: hidden;
        }
        .flex-row {
            flex-wrap: wrap;
        }
        .flex-row > .col {
            flex-basis: 100%;
        }
        .visible-xs {
            display: block;
        }
        .hidden-xs {
            display: none;
        }

        /* navi */
        #navi {
            height: 48px;
            top: -49px;
        }
        #navi.active {
            height: 330px;
        }
        #navi .flex-row {
            align-items: center;
        }
        #navi .flex-row > .col {
            flex-basis: initial;
        }
        #menu-name {
            flex: initial;
            font-size: 17px;
        }
        #menu-name .icon {
            height: 17px;
            display: inline-flex;
            align-items: center;
        }
        .top-link .resume {
            padding-right: 0;
        }
        .top-link .email {
            display: none;
        }
        #navi .menu-list li a {
            font-size: 15px;
        }

        /* scroll */
        #nav-scroll {
            display: none;
            right: 10px;
        }
        #scroll-top {
            margin-top: 8px;
        }

        /* main-content */
        .main-content section {
            padding: 100px 0;
        }
        .main-content .main-title {
            margin-bottom: 20px;
            font-size: 40px;
        }
        .main-content [data-acive="fadeup"] {
            transform: translateY(0);
        }

        #main-view .container {
            margin-top: -28px;
        }
        #main-view .middle-title span {
            font-size: 56px;
            line-height: 1.2;
            height: 1.2em;
            text-align: center;
        }

        #intro h3 {
            margin-bottom: 60px;
        }
        #intro .flex-row {
            margin-bottom: 0;
        }
        .summary-wrap dl {
            margin-bottom: 15px;
            height: auto;
        }
        .summary-wrap dl dt {
            font-size: 18px;
        }
        .summary-wrap .skill-dl dd:before {
            left: -60%;
        }

        #history .txt-box {
            margin-bottom: 40vh;
            padding-top: 0;
            word-break: keep-all;
        }
        #history .main-line,
        #history .sub-line {
            height: 2.4em;
            line-height: 1.2;
            overflow: initial;
        }
        #history .main-line span,
        #history .sub-line span {
            top: 0;
            opacity: 0;
            white-space: initial;
        }
        #history .main-line span.active,
        #history .sub-line span.active {
            opacity: 1;
        }
        #history .main-line {
            font-size: 30px;
        }

        #personality .main-title {
            margin-bottom: 30px;
            font-size: 34px;
            line-height: 1.2;
        }
        .personality-li {
            margin-bottom: 45px;
        }
        .personality-li li {
            display: none;
            line-height: 1.4;
            word-break: keep-all;
        }
        .personality-li li.active {
            display: block;
        }
        .personality-icon {
            margin: 0 auto;
            width: 80vw;
            height: 80vw;
        }
        .personality-icon span {
            font-size: 35vw;
        }
        .personality-wrap .icon-box:before {
            width: 80vw;
            height: 80vw;
        }

        #project .sub-title {
            line-height: 1.4;
        }
        .project-wrap .col:nth-child(1) ul {
            border-radius: 20px 20px 0 0;
            padding-top: 30px;
            padding-bottom: 0;
        }
        .project-wrap .col:nth-child(2) ul {
            border-radius: 0 0 20px 20px;
            padding-top: 0;
        }
        .project-wrap ul li.active a,
        .project-wrap ul li a {
            color: #f5f5f7;
            border-bottom: 1px solid;
        }
        .project-wrap ul .bg {
            display: none;
        }

        #example #major-wrap article {
            margin-top: 0;
            overflow: hidden;
        }
        #major-wrap article img {
            position: static;
            width: 100%;
        }
        #major-wrap article .txt-box {
            position: static;
            left: 0;
            width: 100%;
            opacity: 1;
            padding: 30px 0px 60px;
        }

        #contact {
            margin-bottom: 74px;
            padding-top: 100px;
        }
        #contact .main-title {
            margin-bottom: 30px;
        }
        #contact .contact-li li {
            font-size: 16px;
        }

        .profile-img {
            width: 66vw;
            height: 66vw;
        }
        #contact .contact-li .icon {
            transform: translateY(0px);
        }

        #footer .copy {
            text-align: left;
        }
    }
}

/* responsive -tablet */
@media (max-width: 999px) and (min-width: 768px) {
    .home {
        .summary-wrap .skill-dl dd:before {
            height: 520%;
            width: 160%;
            margin-top: -20%;
            margin-left: 20%;
        }
    }
}
